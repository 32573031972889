(function() {
  var teamSliderMobile = undefined;
  var checkWindowWidth = function() {
    if (window.innerWidth < 720) {
      if (teamSliderMobile === undefined) {
        var currentSlide = document.getElementById('slider-controls__current');
        var sliderControlPrev = document.querySelector('.slider-controls__prev');
        var sliderControlNext = document.querySelector('.slider-controls__next');
        var classSliderControlsHidden = 'slider-controls--hidden';

        teamSliderMobile = Peppermint(document.getElementById('member-slider'), {
          speed: 300,
          onSlideChange: function(n) {
            var position = n + 1;
            currentSlide.innerHTML = position;
            if (position === 1) {
              sliderControlPrev.classList.add(classSliderControlsHidden);
            } else if (position === teamSliderMobile.getSlidesNumber()) {
              sliderControlNext.classList.add(classSliderControlsHidden);
            } else {
              sliderControlPrev.classList.remove(classSliderControlsHidden);
              sliderControlNext.classList.remove(classSliderControlsHidden);
            }
          }
        });

        var toPreviousSlide = function() {
          teamSliderMobile.prev();
        }
        var toNextSlide = function() {
          teamSliderMobile.next();
        }

        teamSliderMobile.controls = {};
        teamSliderMobile.controls['prev'] = addEvent(sliderControlPrev, 'click', toPreviousSlide);
        teamSliderMobile.controls['next'] = addEvent(sliderControlNext, 'click', toNextSlide);
      }
    } else if (teamSliderMobile) {
      teamSliderMobile.destroy();
      teamSliderMobile.controls.prev.remove();
      teamSliderMobile.controls.next.remove();
      teamSliderMobile = undefined;
    }
  }

  function addEvent(el, event, func, bool) {
    if (!event) return;
    el.addEventListener(event, func, !!bool)

    //return event remover to easily remove anonymous functions later
    return {
      remove: function() {
        removeEvent(el, event, func, bool);
      }
    };
  }

  function removeEvent(el, event, func, bool) {
    if (!event) return;
    el.removeEventListener(event, func, !!bool)
  }

  if (document.querySelectorAll('.body--team, .body--team-detail').length) {
    window.addEventListener('resize', checkWindowWidth);
    checkWindowWidth();
  }
})();
