(function() {
  var opener = {};
  var profileSlider = undefined;
  var sliderVisible = false;
  var sliderRoot = document.querySelector('.team-profiles__slider');
  var profiles = []

  var registerEvents = function() {
    var links = [];

    // get all profiles
    var slides = sliderRoot.querySelectorAll('[data-profile-permalink]');
    for(var i = 0, ll = slides.length; i != ll; profiles.push(slides[i++].dataset.profilePermalink));

    opener.title = document.title;
    opener.url = location.pathname+location.search;
    // set to /team/ if already on a profile url
    // FIXME: read from dom
    if (profiles.indexOf(opener.url) >= 0) {
      opener.url = '/team/';
      opener.title = 'inoio gmbh | Team';
    }

    // get all profileLinks
    var profileLinks = document.querySelectorAll('[data-profile-id]');
    for(var i = 0, ll = profileLinks.length; i != ll; links.push(profileLinks[i++]));

    // search for meta tags
    var metaOpener = document.querySelectorAll('meta[name="profile:open"]');
    if(metaOpener.length) {
      var permalink = metaOpener[0].getAttribute('content');
      window.history.replaceState({ profile: permalink }, null, permalink);
      profileSlider.slideTo(profiles.indexOf(permalink), 0);
      showSlider();
    }

    // register event listener
    links.forEach(function(link) {
      link.addEventListener('click', function(e) {
        e.preventDefault();
        var permalink = this.dataset.profileId;
        window.history.pushState({ profile: permalink }, null, permalink);
        profileSlider.slideTo(profiles.indexOf(permalink), 0);
        showSlider();
      });
    });

    var closeButton = sliderRoot.querySelector('.team-profiles__close');
    closeButton.addEventListener('click', function(e) {
      e.preventDefault();
      document.title = opener.title;
      window.history.pushState(null, null, opener.url);
      hideSlider();
    });

    // sliderRoot.addEventListener('click', function(e) {
    //   e.preventDefault();
    //   hideSlider();
    // });

    function advanceSlider(dir) {
      var pos = profileSlider.getCurrentPos();
      var currProf = profiles[pos];
      var inFriends = currProf.startsWith("/friends/")
      do {
        pos = (profiles.length + pos + dir) % profiles.length
      } while (profiles[pos].startsWith("/friends/") !== inFriends);

      profileSlider.slideTo(pos);
    };

    var prevButton = sliderRoot.querySelector('.team-profiles__prev');
    prevButton.addEventListener('click', function(e) {
      e.preventDefault();
      advanceSlider(-1);
    });

    var nextButton = sliderRoot.querySelector('.team-profiles__next');
    nextButton.addEventListener('click', function(e) {
      e.preventDefault();
      advanceSlider(1);
    });

    window.addEventListener("popstate", function(event) {
      if (event.state) {
        var state = event.state;
        if (state.profile) {
          profileSlider.slideTo(profiles.indexOf(state.profile), 0);
          showSlider();
        }
      } else {
        hideSlider();
      }
    });

    document.addEventListener('keydown', function(event) {
      if(sliderVisible == true) {

        switch (event.keyCode) {
          case 32:
          case 39:
            advanceSlider(1);
            break;
          case 37:
            advanceSlider(-1);
            break;
          case 27:
            // TODO: needs cleanup
            document.title = opener.title;
            window.history.pushState(null, null, opener.url);
            hideSlider();
            break;
        }

      }
    });

  };

  var showSlider =  function() {
    document.body.classList.add('body--slider');
    sliderRoot.classList.add('team-profiles__slider--visible');
    // recalculate width to prevent scrollbar or width issues
    profileSlider.recalcWidth();
    sliderVisible = true;
  };

  var hideSlider = function() {
    document.body.classList.remove('body--slider');
    sliderRoot.classList.remove('team-profiles__slider--visible');
    sliderVisible = false;
  };

  var initSlider = function() {
    if (profileSlider === undefined) {
      profileSlider = Peppermint(document.getElementById('team-profiles'), {
        speed: 300,
        onSetup: function() {
          registerEvents();
        },
        onSlideChange: function(n) {
          if(sliderVisible) {
            window.history.replaceState({profile: profiles[n]}, null, profiles[n]);
          }
        }
      });
    } else {
      profileSlider.destroy();
      profileSlider = undefined;
    }
    return profileSlider;
  };

  initSlider();
})();
