(function() {

  var getRandom = function(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  var showRandomTestimonials = function(count) {
    var classQuoteVisible = 'quote__wrapper--visible';
    var nodeList = document.querySelectorAll('.quote .quote__wrapper');
    // nodeList to array
    var nodeArray = [];
    for(var i = 0, ll = nodeList.length; i != ll; nodeArray.push(nodeList[i++]));
    // switch the desired number of testimonials to visible
    for(var i = 0; i < count; i++) {
        var selectedIndex = getRandom(nodeArray.length);
        nodeArray[selectedIndex].classList.add(classQuoteVisible);
        nodeArray.splice(selectedIndex, 1);

    }
    // remove all other testimonials from the DOM
    nodeArray.forEach(function(item) {
      item.remove();
    });
  }

  if (document.querySelectorAll('.body--work').length) {
    showRandomTestimonials(2);
  }
})();
