(function(w, d) {
  var y = w.pageYOffset,
  header = d.getElementsByClassName("header")[0],
  css = "header--active";
  function addClass() {
    y = w.pageYOffset;
    y>0?header.classList.add(css):header.classList.remove(css);
  }
  w.addEventListener("load", addClass);
  w.addEventListener("scroll", addClass);
})(window, document);
